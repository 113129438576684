body{
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0;
    background-color:#21211D;
    text-align: center;
    align-items: center;
    display: flex;
}

.App{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}